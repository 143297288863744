.font-heading {
    font-family: $heading;
    font-weight: 700;
    webkit-font-smoothing: none;
}

.font-body {
    font-family: $body;
    font-weight: 300;
    webkit-font-smoothing: none;
}

h1 {
    @extend .font-body;
    font-size: 3rem;
    line-height: 2;
}

h2 {
    @extend .font-body;
    font-size: 2rem;
    line-height: 1.5;
}

h3 {
    @extend .font-body;
    font-size: 4rem;
    line-height: 1.25;
    margin-bottom: 2rem;
}

h4 {
    @extend .font-body;
    font-size: 2rem;
    line-height: 1.25;
    margin-bottom: 2rem;
}

p {
    @extend .font-body;
    font-size: 1.8rem;
    line-height: 1.8;
    margin-bottom: 1.8rem;
    @include tablet() {
        font-size: 2rem;
    }
}

a {
    text-decoration: none;
    font-weight: bold;
    color: rgba($primary, .75);
    &:hover {
        color: rgba($primary, 1);
    }
}

li {
    @extend .font-body;
    font-size: 2rem;
    line-height: 1.8;
}

.logo {
    @extend .font-body;
    font-size: 2rem;
    font-weight: 700;
    span {
        @extend .font-body;
        font-weight: 100;
        display: inline-block;
        margin-left: -5px;
        text-transform: none;
    }
}

.wrapper-menu {
    h1 {
        @extend .logo;
    }
    span {
        @extend .font-body;
        font-weight: 100;
        display: inline-block;
        margin-left: -5px;
        text-transform: none;
    }
}

.hero-content {
    .title {
        font-size: 4.8rem;
        letter-spacing: -1px;
        line-height: 1;
        font-weight: 700;
    }
    @include tablet() {
        .title {
            font-size: 10rem;
            letter-spacing: -4px;
        }
    }
}
.shadow {
    text-shadow: 1px 1px 0px rgba($black, .1);
}
.feature {
    .area-label {
        font-family: $body;
        font-size: 3rem;
    }
    li {
        font-size: 1.6rem;
    }
}
.content-area {
    h1 {
        font-family: $body;
        font-size: 5rem;
        line-height: 1.25;
        text-align: center;
    }
    h3 {
        line-height: 1.4;
    }
    @include tablet() {
        h1 {
           font-size: 8rem;
        }
    }
}
.project {
    .title {
        @extend .font-heading;
        font-size: 3rem;
        line-height: 1;
    }
    .description {
        @extend .font-body;
        font-size: 1.6rem;
        line-height: 1.5;
    }
}
.main-footer {
    span {
        @extend .font-heading;
        font-size: 1.6rem;
        line-height: 3;
        font-weight: 100;
    }
}