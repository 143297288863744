@import "util/mixins";
@import "util/normalize";
@import "util/variables";
@import "util/typography";

html,
body {
    background-color: $black;
    &.menu-open {
        overflow: hidden;
    }
}

.wrapper-main {
    position: relative;
    opacity: 1;
    transition: opacity .6s;
    z-index: 12;
    
    .menu-open & {
        z-index: 11;
        opacity: .15;
        overflow: scroll;
        height: 100%;
    }

    .main-header {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 6rem;
        background-color: $primary;
        z-index: 11;
        .logo {
            position: absolute;
            left: 3rem;
            color: $secondary;
            z-index: 1;
            a {
                color: $secondary;
            }
        }
    }
    .main-footer {
        position: relative;
        display: block;
        text-align: center;
        color: #FFF;
        background-color: $primary;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 3rem;
            border-bottom: 3rem solid $primary; 
            border-right: 100vw solid $secondary; 
        }
    }
    &.portfolio-page {
        .main-footer {
            &:before {
                border-bottom: 3rem solid $primary; 
                border-right: 100vw solid $primary; 
            }
        }
    }
}

.wrapper-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    .nav-panel {
        background-color: $black;
        position: absolute;
        right: 0;
        display: block;
        width: $menu-wrap-width;
        height: 100%;
        .menu-closed & {
            transform: translate3d(120%,0,0);
            transition: transform .6s ease-in-out;
        }
        .menu-open & {
            box-shadow: 0 0 5rem $black;
            transform: translate3d(0,0,0);
            transition: transform .6s ease-in-out;
        }
        .close {
            left: -5rem;
            top: 2rem;
        }
    }
    .title {
        padding: 2rem 0;
        width: 100%;
        text-align: right;
        color: $secondary;
        h1, h2 {
            padding-right: 1.5rem;
        }
    }
    .icon {
        width: $menu-wrap-width;
        height: 1px;
        display: block;
        margin-bottom: 1rem;
        stroke: rgba($secondary, .5);
    }
    ul {
        position: absolute;
        top: 50%;
        right: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        transform: translate3d(0,100%,0);
        transition: transform .4s;
        a {
            @extend .font-body;
            font-size: 1.4rem;
            text-decoration: none;
            letter-spacing: 1px;
            color: rgba($secondary, .5);
            transition: color .5s;
            &:hover {
                transition: color .5s;
                color: $primary;
            }
        }
        li {
            margin: 0 0 30px 0;
            text-align: center;
            opacity: 0;
            transform: translate3d(0,$menu-wrap-width,0);
            transition: transform .8s;
            &:nth-child(2) {
                transform: translate3d(0,500px,0);
            }
            &:nth-child(3) {
                transform: translate3d(0,1000px,0);
            }
            &:nth-child(4) {
                transform: translate3d(0,1500px,0);
            }
        }
        .menu-open & {
            transform: translate3d(0,-50%,0);
            transition: transform .6s;
            li {
                opacity: 1;
                transform: translate3d(0,0,0);
                transition: opacity 1s, transform .6s;
            }
        }
        .menu-closed & {
            transform: translate3d(0,0,0);
            transition: transform .4s;
        }
    }
    .menu-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 2rem 0;
        small {
            @extend .font-body;
            font-size: 1.4rem;
            letter-spacing: .5px;
            display: block;
            text-align: center;
            color: rgba($secondary, .5);
        }
    }
}

.action-btn {
    position: absolute;
    .icon {
        width: 3.2rem;
        height: 3.2rem;
        transition: stroke .4s, fill .4s;
    }
    &.open {
        right: 2rem;
        .icon {
            fill: $secondary;
            stroke: rgba($secondary, 1);
        }
    }
    &.close {
        transform: rotate( 45deg );
        .icon {
            fill: $tertiary;
            stroke: rgba($tertiary, 1);
        }
    }
    &:hover {
        &.open {
            .icon {
                fill: $tertiary;
                stroke: rgba($tertiary,1);
            }
        }
        &.close {
            .icon {
                fill: $secondary;
                stroke: rgba($secondary,1);
            }
        }
    }
}

.section {
    width: 100%;
    margin: 0;
    .content-area {
        display: block;
        .inner-wrapper {
            padding: 0 2rem;
        }
        h1, h2, h3, h4 {
            color: $primary;
            text-align: center;
        }
    }

    .section-title {
        position: relative;
        padding-bottom: 2rem;
        margin-bottom: 4rem;
        &:after {
            content: '';
            position: absolute;
            width: 15rem;
            height: 1px;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            background-color: rgba($primary, .35);
        }
    }

    .section-subtitle {
        margin-bottom: 4rem;
    }

    @include tablet() {
        .content-area {
            .inner-wrapper {
                padding: 0 5em;
            }
        }
    }
}

.hero {
    position: relative;
    background-color: $primary;
    color: $secondary;
    height: 40rem;
    .hero-content {
        width: 100%;
        text-align: right;
        position: absolute;
        bottom: 6rem;
        padding: 0 2rem 2rem;
        .title {
            margin-bottom: 1rem;
            span {
                margin-left: -1.5rem;
            }
        }
        .subtitle {
            text-transform: none;
        }
    }
    @include tablet {
        .hero-content {
            bottom: 8rem;
            .title {
                span {
                    margin-left: -3rem;
                }
            }
            .subtitle {
                text-transform: none;
            }
        }
    }
}

.about {
    .content-area {
        background-color: #FFF;
        color: $black;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 10rem;
            border-bottom: 10rem solid transparent; 
            border-right: 100vw solid $primary; 
        }
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 10rem;
            border-bottom: 10rem solid $secondary; 
            border-left: 100vw solid transparent; 
        }
    }
}

.portfolio,
.contact {
    padding-top: 12rem;
    background-color: #FFF;
    .content-area {
        display: block;
        overflow: hidden;
        min-height: 50rem;
    }
}

.experience {
    background-color: $secondary;
    color: $black;
    .content-area {
        padding: 2rem 0;
    }
}

.features-area {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    .feature {
        flex-grow: 1;
        position: relative;
        display: block;
        margin-bottom: 3rem;
        width: 100%;
        > div {
            width: 100%;
            text-align: center;
            margin-bottom: 4rem;
        }
        .feature-image {
            .icon-wrapper {
                position: relative;
                margin: 0 auto;
                width: 20rem;
                height: 20rem;
                background-color: #FFF;
                border-radius: 100%;
            }
            .icon {
                width: 20rem;
                position: absolute;
                width: 8rem;
                top: 50%;
                left: 50%;
                fill: $primary;
                transform: translate(-50%, -50%);
            }
            .area-label {
                font-size: 3rem;
                display: none;
            }
        }
        .feature-content {
            .feature-content-wrap {
                width: 100%;
                padding: 0 3rem;
            }
        }
    }
    @include tablet() {
        padding: 0 5rem 2rem;
        .feature {
            display: block;
            height: 40rem;
            width: 50%;
            overflow: hidden;
            margin-bottom: 0;
            cursor: pointer;
            .feature-inner-wrap {
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                transform: translateY(-50%);
                padding: 0 3rem;
            }
            > div {
                height: 40rem;
                margin-bottom: 0;
                opacity: 1;
                transform: translate3d(0, 0, 0);
                transition: opacity .3s ease-out, transform .4s ease-out;
            }
            .feature-image {
                .icon-wrapper {
                    margin-bottom: 3rem;
                }
                .area-label {
                    display: block;
                }
            }
            .feature-content {
                background-color: $primary;
                opacity: 0;
                color: $secondary;
                .area-label {
                    color: $tertiary;
                }
            }
            &:hover {
                .feature-image {
                    transform: translate3d(0, -1rem, 0);
                    opacity: 0;
                }
                .feature-content {
                    transform: translate3d(0, -100%, 0);
                    opacity: 1;
                }
            }
        }
    }
}

.project {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-around;
    background-color: $primary;
    margin-bottom: 2rem;
    padding: 2rem 0;
    > div {
        flex-grow: 1;
        width: 100%;
    }
    .project-image {
        order: 1;
        margin-bottom: 2rem;
        img {
            width: 100%;
        }
    }
    .project-content {
        order: 2;
        position: relative;
        > div {
            padding: 2rem;
        }
        .title {
            text-align: right;
            span {
                color: darken($tertiary, 10%);
                font-weight: 100;
                display: inline-block;
                width: 100%;
                padding-bottom: 1.5rem;
                margin-bottom: 1.5rem;
                border-bottom: 1px solid $tertiary;
            }
        }
        .description {
            padding: 0;
            color: $secondary;
        }
        small {
            display: block;
        }
        a {
            color: $tertiary;
            font-weight: 100;
        }
    }
    // .icon {
    //     width: 100%;
    //     height: 1px;
    //     stroke: darken($tertiary, 10%);
    // }
    @include tablet() {
        flex-flow: row nowrap;
        .project-image {
            order: 2;
        }
        .project-content {
            order: 1;
            .title {
                span {
                    font-size: 4rem;
                    line-height: 1;
                }
            }
        }
    }
    @include desktop() {
        .project-content {
            > div {
                position: absolute;
                padding: 0;
                width: 80%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
