@mixin tablet {
  @media (min-width: $min-tablet) {
  // @media (min-width: $min-tablet) and (max-width: $min-desktop) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $min-desktop) {
    @content;
  }
}