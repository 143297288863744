*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

html, body {
    // height: 100%;
    // width: 100%;
}
hr {
    border: none;
    outline: none;
    background-color: rgba(255,255,255,0.35);
    display: block;
    height: 1px;
    width: 100%;
}
ul, ol {
    list-style: none;
}


// .is-hidden {
//  display: none !important;
// }
// .is-invisible {
//  opacity: 0;
// }

.v-centered {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
// .centered-vh {
//  position: absolute;
//  top: 50%;
//  left: 50%;
//  transform: translate(-50%, -50%);
// }