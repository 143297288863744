@import url('https://fonts.googleapis.com/css?family=Quicksand:300,500,700');

$primary: #1C6B7F; // blue sapphire 
$secondary: #A2CDD8; // pale cerulean (light blue)
$tertiary: #54ff20; // yellow/green
$black: #02171C; // rich black
$green: #10424F; // eagle green


$max_width: 1280px;
$min-tablet: 768px;
$min-desktop: 1024px;

$menu-wrap-width: 26rem;

// $heading: europa, Helvetica, "Helvetica Neue", Sans-serif;
// $body: "Helvetica Neue", Helvetica, Arial, Sans-serif;

$heading: Quicksand, "Helvetica Neue", Helvetica, Arial, Sans-serif;
$body: Quicksand, "Helvetica Neue", Helvetica, Arial, Sans-serif;